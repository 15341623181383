/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import {
  GameBanner4d,
  GameBannerCard,
  GameBannerCard2,
  GameBannerCasino,
  GameBannerCasino2,
  GameBannerInstantGame,
  GameBannerInstantGame2,
  GameBannerLive,
  GameBannerLive2,
  GameBannerSlots,
  GameBannerSlots2,
  GameBannerTable,
  GameBannerTable2,
  GameBannerSport,
  GameBannerSportBr,
  GameBannerLiveMobile,
  GameBannerLiveMobile2,
  GameBannerSlotsMobile,
  GameBannerSlotsMobile2,
  GameBannerCasinoMobile,
  GameBannerCasinoMobile2,
  GameBannerInstantGameMobile,
  GameBannerInstantGameMobile2,
  GameBannerTableGameMobile,
  GameBannerTableGameMobile2,
  GameBannerCardGameMobile,
  GameBannerCardGameMobile2,
  GameBannerSlots3,
  GameBannerSlots4,
  GameBannerSlotsMobile3,
  GameBannerSlotsMobile4,
  GameBannerSlotsBr,
  GameBannerLiveBr,
  GameBannerTableBr,
  GameBannerFishing,
  GameBannerFishingBr,
  GameBannerInstantGameBr,
} from '../../images';

function Banner({ type }) {
  const router = useRouter();
  const windowSize = useSelector((state) => state.setting.windowSize);
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en-BR';

  function _renderText() {
    if (type === 8 || type === '8') {
      return (
        <>
          <div>
            <Swiper
              spaceBetween={7}
              slidesPerView={1}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions'); }}
                >
                  {isEnglish ? (
                    <Image src={GameBannerLive} alt='Instant Games with quick payout' />
                  ) : (
                    <Image src={GameBannerLiveBr} alt='Jogos Instântaneos com ganho rápido' />
                  )}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/live-casino'); }}
                >
                  <Image src={GameBannerLiveMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/live-casino'); }}
                >
                  <Image src={GameBannerLiveMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </>
      );
    } if (type === 12 || type === '12') {
      return (
        <>
          <div>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 1200
                ? 1.3
                : (windowSize?.width >= 1000 ? 1.2 : (windowSize?.width >= 900 ? 1.1 : 1))}
            >
              <SwiperSlide className='cursor-pointer'>

                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  // onClick={() => { router.push('/promotions/slots'); }}
                  onClick={() => { router.push('/promotions'); }}
                >
                  <Image src={GameBannerSlots} alt='gire slot e ganhe jackpot' />
                </div>
              </SwiperSlide>
              {/* <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/netent'); }}
                >
                  <Image src={GameBannerSlots3} alt='Slot Cash Bombs da NetEnt' />
                </div>
              </SwiperSlide> */}
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/nolimitcity'); }}
                >
                  <Image src={GameBannerSlots4} alt='Slot X Cash da NoLimit City' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  // onClick={() => { router.push('/promotions/slots'); }}
                  onClick={() => { router.push('/promotions'); }}
                >
                  <Image src={GameBannerSlots2} alt='Promoção em Jogos de Slot' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/slots'); }}
                >
                  <Image src={GameBannerSlotsMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/netent'); }}
                >
                  <Image src={GameBannerSlotsMobile3} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/nolimitcity'); }}
                >
                  <Image src={GameBannerSlotsMobile4} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/slots'); }}
                >
                  <Image src={GameBannerSlotsMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </>
      );
    } if (type === 5 || type === '5') {
      return (
        <>
          <div>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 1200
                ? 1.3
                : (windowSize?.width >= 1000 ? 1.2 : (windowSize?.width >= 900 ? 1.1 : 1))}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  // onClick={() => { router.push('/promotions/live-casino'); }}
                  onClick={() => { router.push('/promotions'); }}
                >
                  <Image src={GameBannerCasino} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  // onClick={() => { router.push('/promotions/live-casino'); }}
                  onClick={() => { router.push('/promotions'); }}
                >
                  <Image src={GameBannerCasino2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/live-casino'); }}
                >
                  <Image src={GameBannerCasinoMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/live-casino'); }}
                >
                  <Image src={GameBannerCasinoMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </>
      );
    } if (type === 1 || type === '1') {
      return (
        <>
          <div>
            <Swiper
              spaceBetween={7}
              slidesPerView={1}
            />
            <SwiperSlide className='cursor-pointer'>
              <div
                className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                onClick={() => { router.push('/promotions'); }}
              >
                {isEnglish ? (
                  <Image src={GameBannerInstantGame} alt='Jogos Instântaneos com ganho rápido' />
                ) : (
                  <Image src={GameBannerInstantGameBr} alt='Top Brazil gambling' />
                )}
              </div>
            </SwiperSlide>
          </div>

          {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/instant-game'); }}
                >
                  <Image src={GameBannerInstantGameMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/instant-game'); }}
                >
                  <Image src={GameBannerInstantGameMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </>
      );
    } if (type === 3 || type === '3') {
      return (
        <>
          <div>
            <Swiper
              spaceBetween={7}
              slidesPerView={1}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions'); }}
                >
                  {isEnglish ? (
                    <Image src={GameBannerTable} alt='Top Brazil gambling' />
                  ) : (
                    <Image src={GameBannerTableBr} alt='Top Brazil gambling' />
                  )}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/table-game'); }}
                >
                  <Image src={GameBannerTableGameMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/table-game'); }}
                >
                  <Image src={GameBannerTableGameMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </>
      );
    } if (type === 4 || type === '4') {
      return (
        <>
          <div>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 1200
                ? 1.3
                : (windowSize?.width >= 1000 ? 1.2 : (windowSize?.width >= 900 ? 1.1 : 1))}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  //  onClick={() => { router.push('/promotions/card-game'); }}
                  onClick={() => { router.push('/promotions'); }}
                >
                  <Image src={GameBannerCard} alt='Jogos de Carta com Vitória Garantida' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  // onClick={() => { router.push('/promotions/card-game'); }}
                  onClick={() => { router.push('/promotions'); }}
                >
                  <Image src={GameBannerCard2} alt='Promoções para Jogos de Carta' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/card-game'); }}
                >
                  <Image src={GameBannerCardGameMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/card-game'); }}
                >
                  <Image src={GameBannerCardGameMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </>
      );
    }
    // if (type === 13) {
    //   return (
    //     <div>
    //       <Image src={GameBanner4d} alt="Top Braziln gambling" />
    //     </div>
    //   );
    // }
    if (type === 13 || type === '13') {
      return (
        <div>
          <Swiper
            spaceBetween={7}
            slidesPerView={1}
          >
            <SwiperSlide className='cursor-pointer'>
              <div
                className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                onClick={() => { router.push('/promotions'); }}
              >
                {isEnglish ? (
                  <Image src={GameBannerSport} alt='Top Brazil gambling' />
                ) : (
                  <Image src={GameBannerSportBr} alt='Top Brazil gambling' />
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      );
    }
    if (type === 14 || type === '14') {
      return (
        <div>
          <Swiper
            spaceBetween={7}
            slidesPerView={1}
          >
            <SwiperSlide className='cursor-pointer'>
              <div
                className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                onClick={() => { router.push('/promotions'); }}
              >
                {isEnglish ? (
                  <Image src={GameBannerFishing} alt='Top Brazil gambling' />
                ) : (
                  <Image src={GameBannerFishingBr} alt='Top Brazil gambling' />
                )}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      );
    }
  }
  if (type === 0 || type === '0') {
    return (
      <>
        <div>
          <Swiper
            spaceBetween={7}
            slidesPerView={1}
          >
            <SwiperSlide className='cursor-pointer'>
              <div
                className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                onClick={() => { router.push('/promotions'); }}
              >
                {isEnglish ? (
                  <Image src={GameBannerSlots} alt='Top Brazil gambling' />
                ) : (
                  <Image src={GameBannerSlotsBr} alt='Top Brazil gambling' />
                )}
              </div>
            </SwiperSlide>
            {/* <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/netent'); }}
                >
                  <Image src={GameBannerSlots3} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide> */}
            {/* <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/nolimitcity'); }}
                >
                  <Image src={GameBannerSlots4} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide> */}
          </Swiper>
        </div>

        {/* <div className='sm:hidden'>
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 400 ? 2 : 1.2}
            >
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/nolimitcity'); }}
                >
                  <Image src={GameBannerSlotsMobile} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/netent'); }}
                >
                  <Image src={GameBannerSlotsMobile3} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/slots/nolimitcity'); }}
                >
                  <Image src={GameBannerSlotsMobile4} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
              <SwiperSlide className='cursor-pointer'>
                <div
                  className='cursor-pointer rounded-xl leading-unset overflow-hidden'
                  onClick={() => { router.push('/promotions/slots'); }}
                >
                  <Image src={GameBannerSlotsMobile2} alt='Top Braziln gambling' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
      </>
    );
  }
  return (
    <div className='text-white'>
      {_renderText()}
    </div>
  );
}

export default Banner;
